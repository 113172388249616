export const NODE_ENV = "production";
export const WEB_API = "https://web-api.toppersnotes.com";
export const DEV_WEB_API = "https://dev-web-api.toppersnotes.com";
export const ORDER_SERVICE = "https://orders.toppersnotes.com";
export const DEV_ORDER_SERVICE = "https://dev-orders.toppersnotes.com";
export const AUTH_SERVICE = "https://auth.toppersnotes.com";
export const DEV_AUTH_SERVICE = "https://dev-auth.toppersnotes.com";
export const BLOG_SERVICE = "https://blogs-api.dev.toppersnotes.com";
export const DEV_BLOG_SERVICE = "https://blogs-api.dev.toppersnotes.com";
export const BASE_URL = "https://m.toppersnotes.com";
export const FBQ_PIXEL_ID = 1230249700395114;
export const EVENT_SERVICE = "https://events.toppersnotes.com"
export const AW_TRACKING_ID_NEXTJS = "AW-967210754";
export const GA_TRACKING_ID_NEXTJS = "UA-41207601-1";
export const GTM_TRACKING_ID_NEXTJS = "GTM-KJRHJH9";
export const RAZORPAY_KEY = "rzp_live_NepcOOWLTvajs4";

