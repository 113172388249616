// const Banner = () => {
//   return (
//     <div className="bg-tn-blue">
//       <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
//         <div className="flex items-center justify-center p-4 text-white tracking-wider text-sm md:text-lg">
//           <img
//             className="w-11/12 md:w-10/12"
//             height={148}
//             src="https://toppersnotes-main.s3.ap-south-1.amazonaws.com/wp-content/uploads/2022/09/22231535/02-6-1024x148.jpg"
//             alt="Banner"
//           />
//           {/* Hurry! Best Discounts Ever! अभी नहीं तो कभी नहीं! */}
//           {/* Hurry! Sale Price Ends Tonight! */}
//         </div>
//       </div>
//     </div>
//   );
// };
const Banner = () => {
  return (
    <div className="bg-[#c40042]">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex items-center justify-center text-white tracking-wider text-sm md:text-lg">
          <img
            className="w-11/12 md:w-6/12"
            src="/holy_banner.webp"
            alt="Banner"
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
